import { DateTime } from 'luxon';
import delay from './delay';
const rootUrl = process.env.REACT_MIDDLEWARE_ROOT_URL;

const packPostData = (data) => {
  const postdata = new FormData();
  for (const [k, v] of Object.entries(data)) {
    if (v === undefined || v === null) continue;
    postdata.append(k, v);
  }
  return postdata;
};

export const errorCodes = {
  422: "Content cannot be processed",
  404: "Resource not found",
}

const api = async (endpoint, requestInit) => {
  var tries = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    tries++;
    const r = await window.fetch(`${rootUrl}/${endpoint}`, requestInit);
    if (r.status === 429) {
      await delay(tries > 10 ? 10000 : tries * 1000);
      continue;
    }

    const response = await (r.json());

    if (r.status === 422) throw new Error(errorCodes[422], { cause: response.errors });
    if (r.status === 404) throw new Error(errorCodes[404], { cause: response.errors });
    if (r.status >= 400) throw new Error(r.statusText);

    return response;
  }
};

export const requestQuote = (data) =>
  api('form/request-quote', {
    method: 'POST',
    body: packPostData(data),
  });

export const requestCustomQuote = (data) =>
  api('form/request-custom-quote', {
    method: 'POST',
    body: packPostData(data),
  });

export const searchKvK = (name) =>
  api(
    'kvk?' +
      new URLSearchParams({
        handelsnaam: name,
        type: 'hoofdvestiging',
      }),
    {
      method: 'GET',
    }
  ).then((r) => r?.results || []);

export const leaseInterest = (purchasePrice) =>
  api(
    'lease-percentage?' +
      new URLSearchParams({
        lease_amount: purchasePrice, //TODO: should be purchase_price
      })
  ).then((r) => r?.data?.percentage);
export const leaseMaxBalloonPayment = () =>
  api('lease-term-percentage').then((r) => r?.data);

// TODO: create an endpoint for this
export const leaseTermOptions = (productionDate) => {
  if (productionDate === '__DEFAULT')
    return Promise.resolve([12, 24, 36, 48, 60, 72]);
  const fromDate = typeof productionDate === "string" ? DateTime.fromISO(productionDate) : DateTime.fromMillis(productionDate * 1000);
  const ageInMonths = Math.ceil(-fromDate.diffNow('months').months);

  return Promise.resolve(
    [12, 24, 36, 48, 60, 72].filter((i) => i + ageInMonths <= 144)
  );
};

export const feedbackSummary = async () => api('company-reviews/summary');

export const feedbackPage = async (page, pageSize) =>
  api(`company-reviews/all?page=${page}&page_size=${pageSize}`);

export const feedbackFeaturedPage = async (page, pageSize, random = false) =>
  api(`company-reviews/featured?random=${random ? 1 : 0}&page=${page}&page_size=${pageSize}`);
